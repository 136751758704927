import { gsap } from "gsap";

export default () => {
  return {
    init() {
      console.log("init ani-bg.js");
      const q = gsap.utils.selector(this.$el);
      gsap.set(q(".ani-bg .svg"), { transformOrigin: "center" });
      gsap.to(q(".ani-bg .svg"), { rotateZ: 360, duration: 750, repeat: -1 });
    },
  };
};
