// core version + navigation, pagination modules:
import { fromJSON } from "postcss";
import Swiper from 'swiper';
import {
  Navigation,
  Pagination,
  Parallax,
  
} from "swiper/modules";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/parallax";

export default (bulletTitlesJson) => {
  return {
    init() {
      const bulletTitles = JSON.parse(bulletTitlesJson);
      console.log("init swiper.js");
      Swiper.use([Navigation, Pagination]);

      const swiper = new Swiper(this.$el, {
        slidesPerView: 1,
        spaceBetween: 0,

        direction: "vertical",
        // mousewheel: true,
        speed: 420,
        parallax: true,
        cssMode: !window.matchMedia("(pointer: coarse)").matches,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className}">
              <span class="swiper-bullet-title">${bulletTitles[index]}</span>
            </span>`;
          },
        },
        modules: [Navigation, Pagination, Parallax],
      });

      window.addEventListener("nextSlide", () => {
        swiper.slideNext();
      });

      window.addEventListener("prevSlide", () => {
        swiper.slidePrev();
      });
    },
  };
};
