import "@css/tailwind.pcss";
import "lazysizes";
import PineconeRouter from "pinecone-router";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import collapse from "@alpinejs/collapse";

import router from "./router";
import modal from "./store/modal";
import slideshow from "./slideshow";
import scrollSwipeDetect from "./scroll-swipe-detect";
import swiper from "./swiper";
import swiperCustom from "./swiper-custom";
import aniBg from "./ani-bg";
import activities from "./activities";
import masonry from "./masonry";

import "./bookvisit";
import "./intro";




Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(PineconeRouter);

Alpine.store("modal", modal);
Alpine.data("slideshow", slideshow);
Alpine.data("swiper", swiper);
Alpine.data("swiperCustom", swiperCustom);
Alpine.data("aniBg", aniBg);
Alpine.data("scrollSwipeDetect", scrollSwipeDetect);
Alpine.data("router", router);
Alpine.data("activities", activities);
Alpine.data("masonry", masonry);

window.Alpine = Alpine;
Alpine.start();
