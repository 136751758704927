import axios from "axios";

export default {
  show: false,
  entryContent: null,
  init() {
    // if url has query param
    if (window.location.search.includes("modal")) {
      // get entry id from query param
      const entryId = window.location.search.split("=")[1];
      // open modal
      this.open(entryId);
    }
  },
  open(entryId) {
    this.show = true;
    const self = this;
    // add query param to url
    window.history.pushState({}, "", `?modal=${entryId}`);
    axios.get(`/modal/${entryId}`).then((response) => {
      // Parse the response data and extract script tags
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(response.data, "text/html");
      const scriptTags = htmlDoc.getElementsByTagName("script");

      // Set the entry content with the response data
      this.entryContent = response.data;

      setTimeout(() => {
        // Create and append script elements to the parent elements
        for (let i = 0; i < scriptTags.length; i++) {
          const scriptTag = scriptTags[i];
          const scriptElement = document.createElement("script");

          if (scriptTag.src) {
            scriptElement.src = scriptTag.src;
            // add async attribute to script element
            scriptElement.setAttribute("async", "");
            // add defer attribute to script element
            scriptElement.setAttribute("defer", "");
          } else {
            // If 'src' attribute is not present, set the innerHTML of the script element
            scriptElement.innerHTML = scriptTag.innerHTML;
          }

          const parentSelector = scriptTags[i].parentNode.id;
          const parentElement = document.getElementById(parentSelector);
          parentElement.appendChild(scriptElement);
        }
      }, 50);
    });
  },
  close() {
    this.show = false;
    // remove query param from url
    window.history.pushState({}, "", window.location.pathname);
    setTimeout(() => {
      this.entryId = null;
    }, 500);
  },
};
