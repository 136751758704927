import Alpine from "alpinejs";
import gsap from "gsap";

Alpine.data("intro", () => {
  return {
    init() {
      console.log("init intro.js");
      const tl = gsap.timeline({ defaults: { duration: 1 } });
      tl.from(".block", {
        opacity: 0,
        y: 10,
        duration: 1.2,
        stagger: 0.1,
        ease: "power4.out",
        clearProps: "all",
      });
    },
  };
});
