export default () => {
  return {
    init() {
      // // Only add the event listener if the device is not a touch device
      // if (!window.matchMedia("(pointer: coarse)").matches) {
      //   let accumulatedDelta = 0;
      //   const threshold = 300; // Adjust this value to suit your needs
      //   let allowSwipe = true;
      //   const delay = 1000; // Delay in milliseconds
      //   // Custom events
      //   const nextSlideEvent = new Event("nextSlide");
      //   const prevSlideEvent = new Event("prevSlide");
      //   // Listen to scroll event
      //   const self = this;
      //   window.addEventListener("wheel", function (event) {
      //     if (!allowSwipe) {
      //       return;
      //     } else if (self.$store.modal.show) {
      //       return;
      //     }
      //     // Accumulate wheel delta
      //     accumulatedDelta += Math.abs(event.deltaY);
      //     if (accumulatedDelta >= threshold) {
      //       accumulatedDelta = 0;
      //       // Normalize wheel delta across browsers
      //       const normalized = Math.sign(event.deltaY);
      //       if (normalized < 0) {
      //         window.dispatchEvent(prevSlideEvent);
      //       } else {
      //         window.dispatchEvent(nextSlideEvent);
      //       }
      //       // Prevent further swipes for the duration of the delay
      //       allowSwipe = false;
      //       setTimeout(() => {
      //         allowSwipe = true;
      //       }, delay);
      //     }
      //   });
      // }
    },
  };
};
