import Bricks from "bricks.js";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default () => {
    return {
        bricks: null,
        activeCardPhoto: null,
        activeCard: null,
        activeCardEl: null,
        initialHtmlContent: null,
        scrolled: false,
        resize() {
            console.log('resize')
            this.setupSizes(true)
            this.bricks.resize(true)
                .pack();
        },
        setupSizes(packed = false) {
            const items = packed ? '.item' : '.item:not([data-packed])';
            this.$refs.masonry.querySelectorAll(items).forEach((item) => {

                // mediaqueries (mobile first, min-width: 768 and min-width: 1024)
                const columns = window.matchMedia('(min-width: 1024px)').matches ? 4 : window.matchMedia('(min-width: 768px)').matches ? 3 : 2;


                const itemWidth = window.innerWidth / columns - 10;
                item.style.width = itemWidth + 'px';



                // get the proportions of the images attributes then set the height of the item to the same proportions
                const img = item.querySelector('img');

                const imgWidth = Number(img.getAttribute('width'));
                const imgHeight = Number(img.getAttribute('height'));
                const itemHeight = itemWidth / imgWidth * imgHeight;

                item.style.height = itemHeight + 'px';

            });
        },

        setupScrollTrigger() {
            ScrollTrigger.batch('.item:not([data-packed])', {
                scroller: this.$el.closest('.inspiration'),
                onEnter: batch => gsap.fromTo(batch, { opacity: 0, y: 10, }, { opacity: 1, y: 0, stagger: 0.05 }),
            });
        },
        init() {

            gsap.to('.solhem-logo', { autoAlpha: 1, duration: 0.5 })
            gsap.fromTo('.intro-text', { autoAlpha: 0, y: -10 }, { y: 0, autoAlpha: 1, duration: 1.6, ease: 'quint', delay: 0.25 })
            gsap.fromTo('.solhem-logo svg path', { opacity: 0, y: 10 }, { opacity: 1, y: 0, stagger: 0.02, duration: 0.5 })
            this.$el.closest('.inspiration').addEventListener('scroll', e => {
                if (!this.scrolled) {
                    this.scrolled = true;
                    this.hideIntro();
                }
                const preloadDistance = 1000;
                if (e.target.scrollHeight - e.target.scrollTop - preloadDistance <= e.target.clientHeight) {
                    this.loadMore();
                }
            })

            this.initialHtmlContent = this.$refs.masonry.cloneNode(true);
            this.setupScrollTrigger();
            this.setupSizes()
            gsap.set('.item .text', { autoAlpha: 0, y: 10 })

            this.bricks = Bricks({
                container: this.$refs.masonry,
                packed: 'data-packed',
                position: true,
                sizes: [
                    { columns: 2, gutter: 8 },
                    { mq: '768px', columns: 3, gutter: 8 },
                    { mq: '1024px', columns: 4, gutter: 8 }
                ]
            }).resize(true)
                .pack();

        },
        hideIntro() {
            gsap.to('.inspo-intro', { autoAlpha: 0, duration: 0.5 })
            gsap.to('.item .text', { autoAlpha: 1, y: 0, stagger: 0.05, duration: 0.5 })
        },

        showCard(slug) {
            this.activeCardPhoto = this.$event.currentTarget;
            this.activeCardPhoto.classList.add('active');
            this.activeCard = slug
            this.$nextTick(() => {
                this.activeCardEl = this.$refs.cardView.querySelector('.card.active')

                gsap.fromTo(this.activeCardEl, { scale: 0.6, rotateY: -90 }, { delay: 0.12, scale: 1, rotateY: 0, duration: 0.35, ease: 'back' })
            });

        },

        hideCard() {
            this.activeCardPhoto.classList.remove('active');
            this.activeCard = null
            this.activeCardPhoto = null
        },

        loadMore() {
            Array.from(this.initialHtmlContent.childNodes).forEach(child => {
                if (child.nodeType === Node.ELEMENT_NODE) { // Ensure it's an element node
                    child.classList.remove('opacity-0');
                }
                const clone = child.cloneNode(true);
                this.$refs.masonry.appendChild(clone);
            });

            this.setupSizes()
            this.setupScrollTrigger();
            this.bricks.update()

        }
    }
}


