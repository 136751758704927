export default () => {
  return {
    numActivities: 0,
    numSelectedActivities: 0,
    showFilter: false,
    activities: null,
    areas: null,
    selectedActivities: [],
    selectedAreas: [],
    toggleActivityType(typeSlug) {
      this.selectedActivities.includes(typeSlug)
        ? (this.selectedActivities = this.selectedActivities.filter(
            (act) => act !== typeSlug
          ))
        : (this.selectedActivities = this.selectedActivities.concat(typeSlug));
    },
    toggleActivityArea(areaSlug) {
      this.selectedAreas.includes(areaSlug)
        ? (this.selectedAreas = this.selectedAreas.filter(
            (act) => act !== areaSlug
          ))
        : (this.selectedAreas = this.selectedAreas.concat(areaSlug));
    },
    areaName(slug) {
      const item = this.areas.find((item) => item.slug === slug);
      return item ? item.title : slug;
    },
    activityName(slug) {
      const item = this.activities.find((item) => item.slug === slug);
      return item ? item.title : slug;
    },
    isSelected(typeSlug, areaSlug) {
      if (
        this.selectedActivities.length === 0 &&
        this.selectedAreas.length === 0
      ) {
        return true;
      }

      if (
        (this.selectedAreas.includes(areaSlug) &&
          this.selectedActivities.length === 0) ||
        (this.selectedActivities.includes(typeSlug) &&
          this.selectedAreas.length === 0)
      ) {
        return true;
      } else if (
        this.selectedAreas.includes(areaSlug) &&
        this.selectedActivities.includes(typeSlug)
      ) {
        return true;
      }

      return false;
    },
    init() {
      // get activities from API and store in data
      fetch("/api/cats/activityTypes.json")
        .then((response) => response.json())
        .then((data) => {
          this.activities = data.data;
        });
      fetch("/api/cats/areas.json")
        .then((response) => response.json())
        .then((data) => {
          this.areas = data.data;
        });
      this.numActivities = this.$el.querySelectorAll(".activity").length;
      this.numSelectedActivities = this.numActivities;

      const updateNumActivities = function () {
        this.$nextTick(() => {
          this.numSelectedActivities =
            this.$el.querySelectorAll(".activity.selected").length;
        });
      }.bind(this);

      this.$watch("selectedActivities", updateNumActivities);
      this.$watch("selectedAreas", updateNumActivities);
    },
  };
};
