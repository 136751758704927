import { gsap } from "gsap";

export default () => {
  return {
    init() {
      const tl = gsap.timeline({ repeat: -1, defaults: { duration: 1 } });
      const slides = gsap.utils.toArray(".slide", this.$el);

      for (const slide of slides) {
        const q = gsap.utils.selector(slide);
        gsap.set(slide, { visibility: "hidden" });

        tl.set(slide, {
          visibility: "visible",
        });

        tl.fromTo(
          q(".lazy-cover"),
          {
            filter: "brightness(0)",
          },
          {
            filter: "brightness(1)",
            duration: 1,
            ease: "power4.out",
          }
        );
        tl.from(
          q(".slide-overlay .left"),
          {
            opacity: 0,
            x: -20,
            duration: 1.5,
            ease: "quint.out",
          },
          "<"
        );
        tl.from(
          q(".slide-overlay .right"),
          {
            opacity: 0,
            x: 20,
            duration: 1.5,
            ease: "quint.out",
          },
          "<"
        );
        tl.fromTo(
          q(".lazy-cover"),
          {
            scale: 1,
          },
          {
            scale: 1.3,
            duration: 20,
            ease: "linear",
          },
          "<"
        );
        tl.to(
          q(".lazy-cover"),
          {
            filter: "brightness(0)",
            duration: 1,
            ease: "power4.out",
          },
          ">-1"
        );
        tl.to(
          q(".slide-overlay .left"),
          {
            opacity: 0,
            x: -20,
            duration: 0.7,
            ease: "linear",
          },
          ">-1.5"
        );
        tl.to(
          q(".slide-overlay .right"),
          {
            opacity: 0,
            x: 20,
            duration: 0.7,
            ease: "linear",
          },
          ">-1.5"
        );
      }
    },
  };
};
